import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { AdvisorProfileTopper, BodyContent, SEO, CTA } from 'components';
import { ThemeContext } from 'utils/constants';

const AdvisorProfile = ({
  data: {
    page: {
      seo,
      cta,
      hideFooterCta,
      advisorName,
      headline,
      intro,
      advisorDataLabelLeft,
      advisorDataTextLeft,
      advisorDataLabelRight,
      advisorDataTextRight,
      content,
      image,
    },
  },
  location,
}) => {
  const context = useContext(ThemeContext);

  const advisorData = [
    {
      label: advisorDataLabelLeft,
      text: advisorDataTextLeft,
    },
    {
      label: advisorDataLabelRight,
      text: advisorDataTextRight,
    },
  ];

  return (
    <div className={context}>
      <Helmet
        bodyAttributes={{
          class: 'has-animation',
        }}
      />
      <SEO
        title={advisorName}
        shareImage={image}
        {...seo}
        pathname={location.pathname}
      />
      <AdvisorProfileTopper
        title={headline}
        name={advisorName}
        intro={intro}
        image={image}
        data={advisorData}
      />
      {content && <BodyContent content={content} location={location} />}
      {!hideFooterCta && <CTA {...cta} onFooter />}
    </div>
  );
};

AdvisorProfile.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default AdvisorProfile;

export const query = graphql`
  query AdvisorProfileQuery($slug: String!) {
    page: contentfulAdvisorProfilePage(slug: { eq: $slug }) {
      advisorName
      headline
      intro {
        intro
      }
      advisorDataLabelLeft
      advisorDataTextLeft
      advisorDataLabelRight
      advisorDataTextRight
      content {
        json
      }
      cta {
        ...CTA
      }
      hideFooterCta
      seo {
        ...SEO
      }
      image {
        description
        title
        file {
          url
        }
      }
    }
  }
`;
